import React from 'react'
import { ensureHttp } from 'modules/link'
import openSmsWindow from 'sms/openSmsWindow'

import { useBusinessCard } from 'components/BusinessCard/context'
import { Section, Row, Left, SocialLink, SocialDiv } from 'components/BusinessCard/components/StyledComponents'
import { PERSON_TYPE } from 'components/BusinessCard'

const googleSearchQuery = (resource, resourceType) => {
  let query = ''

  if (resourceType === PERSON_TYPE) {
    if (resource.full_name) { query = resource.full_name }
    if (resource.company_name) { query += ` ${resource.company_name}` }
  } else {
    if (resource.name) { query = resource.name }
  }

  return query.trim()
}

const googleLink = (resource, resourceType) => {
  return `https://www.google.com/search?q=${googleSearchQuery(resource, resourceType)}`
}

const sms = (person) => {
  return (
    <SocialDiv
      title="Send SMS"
      onClick={(e) => {
        window.trackableEvent.sms.clickSmsIcon({ smsEnable: Boolean(window.Features.sms_support) })
        openSmsWindow({ personId: person.id })
      }}
    >
      <i className="fas fa-sms" />
    </SocialDiv>
  )
}

const twitter = (resource) => {
  return (
    <SocialLink
      href={resource.twitter_url}
      className={resource.twitter ? 'present' : 'missing'}
      target="_blank"
      rel="noopener noreferrer"
      title={resource.twitter}
      onClick={(e) => !resource.twitter_url && e.preventDefault()}
    >
      <i className="fa-brands fa-square-x-twitter" />
    </SocialLink>
  )
}

const linkedIn = (resource) => {
  return (
    <SocialLink
      href={ensureHttp(resource.linked_in_url)}
      className={resource.linked_in_url ? 'present' : 'missing'}
      target="_blank"
      title={resource.linked_in_url}
      rel="noopener noreferrer"
    >
      <i className="fab fa-linkedin" />
    </SocialLink>
  )
}

const facebook = (resource) => {
  return (
    <SocialLink
      href={ensureHttp(resource.facebook_url)}
      className={resource.facebook_url ? 'present' : 'missing'}
      target="_blank"
      rel="noopener noreferrer"
      title={resource.facebook_url}
    >
      <i className="fab fa-facebook-square" />
    </SocialLink>
  )
}

const instantMessage = (resource) => {
  return (
    <SocialLink
      href="#"
      className={resource.instant_message ? 'present' : 'missing'}
      title={resource.instant_message}
    >
      <i className="far fa-comments" />
    </SocialLink>
  )
}

const renderGoogleSearchQuery = (resource, resourceType) => {
  return (
    <SocialLink
      href={googleLink(resource, resourceType)}
      className={googleSearchQuery(resource, resourceType).length ? 'present' : 'missing'}
      target="_blank"
      rel="noopener noreferrer"
      title="Google search"
    >
      <i className="fab fa-google" />
    </SocialLink>
  )
}

const SocialSection = () => {
  const { cardMeta: { showOnlyFields }, resource, resourceType } = useBusinessCard()

  const isPerson = resourceType === PERSON_TYPE
  const showSocial = showOnlyFields.includes('twitter') || showOnlyFields.includes('linked_in_url') || showOnlyFields.includes('facebook_url') || showOnlyFields.includes('instant_message')

  if (!showSocial) return null

  return (
    <Section style={{ marginTop: '10px' }}>
      <Row>
        <Left style={{ maxWidth: '200px' }}>
          {isPerson && window.Features.sms_feature && sms(resource)}
          {twitter(resource)}
          {linkedIn(resource)}
          {facebook(resource)}
          {instantMessage(resource)}
          {renderGoogleSearchQuery(resource, resourceType)}
        </Left>
      </Row>
    </Section>
  )
}

export default SocialSection
