/* global Helpers */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'

import { getCurrentAccountSelector, getCurrentUserSelector } from 'selectors'
import { formatHoursToFirstContact } from 'lib/helpers'
import UserNamePresenter from 'lib/user_name_presenter'

import { H6, colors } from '@PipelineDeals/shared-react-components'

import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

import { getDealStatuses, getLeadSources } from 'selectors/Account'
import { useBusinessCard } from 'components/BusinessCard/context'

import CompanyName from 'components/BusinessCard/components/CompanyName'
import CreatedUpdated from 'components/BusinessCard/components/CreatedUpdated'
import DealOwner from 'components/BusinessCard/components/DealOwner'
import DealStagePipeline from 'components/BusinessCard/components/DealStagePipeline'
import Description from 'components/BusinessCard/components/Description'
import PrimaryContact from 'components/BusinessCard/components/PrimaryContact'
import SimpleSection from 'components/BusinessCard/components/SimpleSection'
import Tags from 'components/BusinessCard/components/Tags'

import styled from 'styled-components'

const Divider = styled.hr`
  color: ${colors.structure.tertiary};
  margin: 10px 0;
`

const DealAmount = styled.span`
  color: #33a242;
  font-weight: normal;
`

const Deal = () => {
  const {
    cardMeta: {
      showAssociationsCount,
      showMainDivider
    },
    resource: deal
  } = useBusinessCard()

  const dealStatuses = useSelector(getDealStatuses)
  const dealSources = useSelector(getLeadSources)
  const selectedCurrencies = useSelector(getCurrentAccountSelector).selected_currencies
  const dealStages = useSelector(getCurrentAccountSelector).deal_stages
  const currentUser = useSelector(getCurrentUserSelector)

  const associationsCount = useMemo(() => {
    if (!deal.person_ids) { return null }
    const peopleCount = `(${deal.person_ids.length}) ${pluralize('Person', deal.person_ids.length)}`
    return (
      <Section>
        <Row>
          <Left />
          <Right>
            <H6>{peopleCount}</H6>
          </Right>
        </Row>
      </Section>
    )
  }, [deal])

  const dealStatus = useMemo(() => {
    if (!deal.status) { return null }

    const status = dealStatuses.find(ds => ds.id === deal.status)
    if (!status) { return null }

    return <span><i className="fas fa-circle" style={{ color: `#${status.hex_color}` }} /> {status.name}</span>
  }, [deal, dealStatuses])

  const dealSource = useMemo(() => {
    if (deal.source) { return deal.source.name }
    if (!deal.source_id) { return null }

    const source = dealSources.find(ds => ds.id === deal.source_id)
    return source?.name
  }, [deal, dealSources])

  const dealAge = useMemo(() => {
    if (deal.created_at) {
      const days = Helpers.daysSince(deal.created_at)
      if (days === 1) {
        return days + ' day'
      } else {
        return days + ' days'
      }
    } else {
      return ''
    }
  }, [deal])

  const bonus = useMemo(() => {
    const { bonus, currency_id: currencyId } = deal

    if (bonus) {
      const currency = selectedCurrencies.find(c => c.id === currencyId) || currentUser.currency
      return Helpers.formatCurrency(
        bonus,
        currency ? currency.symbol : undefined
      )
    } else {
      return ''
    }
  }, [deal])

  const commission = useMemo(() => {
    const { commission, currency_id: currencyId } = deal

    if (commission) {
      const currency = selectedCurrencies.find(c => c.id === currencyId) || currentUser.currency
      return Helpers.formatCurrency(
        commission,
        currency ? currency.symbol : undefined
      )
    } else {
      return ''
    }
  }, [deal])

  const currency = useMemo(() => {
    const { currency_id: currencyId } = deal

    const currency = selectedCurrencies.find(c => c.id === currencyId)
    if (currency) {
      return `${currency.code} - ${currency.symbol}`
    } else {
      return ''
    }
  }, [deal])

  const latestDealStageChange = useMemo(() => {
    const { latest_deal_stage_change: dealStageChange, deal_stage_id: dealStageId } = deal

    const currentStage = dealStages.find(ds => ds.id === dealStageId)

    if (!dealStageChange || !currentStage || ![0, 100].includes(currentStage.percent) || !dealStageChange.old_deal_stage_name) {
      return ''
    }
    return dealStageChange.old_deal_stage_name
  }, [deal])

  return (
    <>
      <DealOwner />

      <DealStagePipeline />
      <SimpleSection
        field="value"
        value={deal.value ? <DealAmount>{deal.currency_symbol || deal.currency?.symbol}{deal.value}</DealAmount> : null}
        label="Amount"
      />

      <SimpleSection
        field="deal_value"
        value={deal.deal_value ? <DealAmount>{deal.currency_symbol || deal.currency?.symbol}{deal.deal_value}</DealAmount> : null}
        label="Amount"
      />

      <CompanyName />

      {showMainDivider && <Divider />}

      <SimpleSection field="status" value={dealStatus} label="Status" />
      <SimpleSection field="source_id" value={dealSource} label="Source" />
      <SimpleSection field="product_line_ids" value={deal.product_lines?.map(pl => pl.name).join(', ')} label="Performance lane" />
      <SimpleSection field="revenue_type_id" value={deal.revenue_type?.name} label="Revenue Type" />
      <SimpleSection field="probability" value={deal.probability ? `${deal.probability}%` : null} label="Probability" />

      <PrimaryContact />
      <Description />
      <Tags />

      <SimpleSection field="deal_loss_reason_id" value={deal.deal_loss_reason?.name} label="Loss reason" />
      <SimpleSection field="deal_won_reason_id" value={deal.deal_won_reason?.name} label="Won reason" />

      <SimpleSection field="created_at" value={dealAge} label="Age" />
      <SimpleSection field="expected_close_date" value={Helpers.formatDate(deal.expected_close_date)} label="Expected close" />
      <SimpleSection field="closed_time" value={Helpers.formatDate(deal.closed_time)} label="Actual close" />

      <SimpleSection field="bonus" value={bonus} label="Bonus" />
      <SimpleSection field="commission" value={commission} label="Commission" />
      <SimpleSection field="commission_rate" value={deal.commission_rate ? `${deal.commission_rate}%` : ''} label="Commission Rate" />
      <SimpleSection field="currency" value={currency} label="Currency" />

      <SimpleSection field="date_of_first_activity" value={Helpers.formatDateTime(deal.date_of_first_activity)} label="First Activity" />
      <SimpleSection field="hours_to_first_activity" value={formatHoursToFirstContact(deal.hours_to_first_activity)} label="Time To First Activity" />
      <SimpleSection field="days_in_stage" value={deal.days_in_stage} label="Days In Stage" />
      <SimpleSection field="days_since_last_activity" value={deal.days_since_last_activity} label="Days Since Last Activity" />
      <SimpleSection field="latest_deal_stage_change" value={latestDealStageChange} label="Stage Before Closed" />

      <SimpleSection field="created_by_user_id" value={<div dangerouslySetInnerHTML={{ __html: UserNamePresenter.nameForListing(deal.created_by_user_id) }} />} label="Created By" />
      <SimpleSection field="updated_by_user_id" value={<div dangerouslySetInnerHTML={{ __html: UserNamePresenter.nameForListing(deal.updated_by_user_id) }} />} label="Updated By" />

      <SimpleSection field="is_archived" value={deal.is_archived ? 'Yes' : 'No'} label="Archived" />

      <CreatedUpdated />

      {showAssociationsCount && associationsCount}
    </>
  )
}

export default Deal
