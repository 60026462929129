import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, Subtext, H4, Popover, TertiaryButton } from '@PipelineDeals/shared-react-components'

import { useBusinessCard } from 'components/BusinessCard/context'

export const PopoverWrapper = ({ children }) => {
  const { cardMeta: { element } } = useBusinessCard()

  const hasElement = element !== undefined

  if (hasElement) {
    return (
      <Popover
        target={element}
        placement="bottom"
        flip
        show
        hideArrow
        style={{
          borderRadius: '3px',
          border: '1px solid #CCCCCC',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
          padding: '10px'
        }}
      >
        {children}
      </Popover>
    )
  } else {
    return <>{children}</>
  }
}

PopoverWrapper.propTypes = {
  children: PropTypes.node
}

export const SectionsWrapper = styled.div`
  display: ${props => props.wrapped ? 'flex' : ''};
  flex-direction: ${props => props.wrapped ? 'column' : ''};
  flex-wrap: ${props => props.wrapped ? 'wrap' : ''};
  column-gap: 10px;
  max-height: ${props => props.wrapped ? `${props.wrapped}px` : 'none'};
  overflow-y: ${props => props.wrapped ? 'hidden' : 'inherit'};
  overflow-x: ${props => props.wrapped ? 'auto' : 'inherit'};
`

export const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export const Sections = ({ children, wrapped }) => {
  const { cardMeta, setCardMeta, resourceId } = useBusinessCard()

  const sectionRef = React.useRef()
  React.useLayoutEffect(() => {
    if (!sectionRef.current) return

    const isOverFlowing = sectionRef.current.clientWidth < sectionRef.current.scrollWidth - 20
    if (isOverFlowing !== cardMeta.overflowing) setCardMeta({ ...cardMeta, overflowing: isOverFlowing })
  }, [sectionRef?.current?.clientWidth, sectionRef?.current?.scrollWidth, resourceId])

  const handleShowMore = () => {
    sectionRef.current.style.maxHeight = parseInt(sectionRef.current.clientHeight) + 280 + 'px'
    if (cardMeta.overflowing) setCardMeta({ ...cardMeta, overflowing: false })
  }

  return (
    <>
      <SectionsWrapper ref={sectionRef} wrapped={wrapped}>
        {children}
      </SectionsWrapper>
      {cardMeta.overflowing && (
        <ShowMore>
          <TertiaryButton
            content="Show More"
            onClick={handleShowMore}
            leadingContent={<i className="fas fa-caret-down" />}
            trailingContent={<i className="fas fa-caret-down" />}
          />
        </ShowMore>
      )}
    </>
  )
}

Sections.propTypes = {
  children: PropTypes.node,
  wrapped: PropTypes.number
}

export const CardWrapper = ({ children, type = 'card' }) => {
  const { cardMeta: { hideOnLeave }, callbacks: { onClose, setIsOver } } = useBusinessCard()

  useEffect(() => setIsOver === undefined ? undefined : setIsOver(true), [])

  return (
    <div
      id={`${type.toLowerCase()}-content`}
      onMouseLeave={() => { hideOnLeave && onClose && onClose(false) }}
      onMouseEnter={() => { hideOnLeave && setIsOver && setIsOver(true) }}
    >
      {children}
    </div>
  )
}

CardWrapper.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string
}

export const Name = styled(H4)`
  color: ${colors.primary.blue};
  display: flex;

  > button {
    margin-left: 5px;
  }

  > span > .key-contact {
    margin-right: 5px;
  }
`
export const NameWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

export const Owner = styled(Subtext)`
  margin-top: 5px;
  color: ${colors.text.secondary};
`

export const Row = styled.div`
  display: flex;
`

export const Section = styled.div`
  font-size: 14px;
  margin-bottom: 5px;

  .show-on-hover {
    visibility: hidden !important;
  }

  &:hover {
    .show-on-hover {
      visibility: visible !important;
    }
  }
`

export const SectionTitle = styled(Section)`
  margin-bottom: 12px;
`

export const Left = styled.div`
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  color: ${colors.text.tertiary};
  font-size: 14px;
  font-weight: normal;
  flex: 4;
  max-width: 100px;
  text-overflow: ellipsis;
  margin-right: 10px;

  &.dropdown {
    overflow: visible;
  }
`

export const Right = styled.div`
  font-size: 14px;
  width: ${props => props.fullWidth ? 'calc(100% - 80px - 18px)' : '300px'};
  color: ${colors.text.primary};
  text-align: left;
  flex: 6;

  a {
    color: ${colors.primary.blue}
  }
`

export const MapLink = styled.a`
  float: right;
  margin-right: 12px;
`

export const SocialLink = styled.a`
  margin-right: 8px;
  font-size: 20px;

  &.missing {
    color: ${colors.structure.quaternary};
    cursor: default;
  }
`

export const SocialDiv = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-right: 8px;
  font-size: 20px;
  color: ${colors.primary.blue};
`

export const HoverLabel = styled.label`
  padding: 3px 20px !important;

  &:hover {
    text-decoration: none;
    background-color: #f8f8f8;
    color: #000;
  }
`

export const RecordIcon = styled.i`
    width: 35px;
    height: 35px;
    border-radius: 17px;
    font-size: 35x;
    line-height: 35px;
    text-align: center;
    background: ${colors.structure.tertiary};
    color: ${colors.text.secondary};
`

const InterpunctIconContainer = styled.i`
  font-size: 5px;
  margin: 0 3px;
  vertical-align: middle;
  padding-bottom: 2px;
`

export const Interpunct = () => {
  return <InterpunctIconContainer className="fas fa-circle" />
}

export const InterpunctJoin = ({ items }) => {
  const filteredItems = items.filter(i => i && i !== undefined && i !== 0)

  return filteredItems.length > 0 ? (
    filteredItems.reduce((result, item) => (
      <>
        {result}&nbsp;<Interpunct />&nbsp;{item}
      </>
    ))
  ) : (
    <React.Fragment /> // eslint-disable-line
  )
}

InterpunctJoin.propTypes = {
  items: PropTypes.array.isRequired
}
