import React, { } from 'react'

import {
  SectionTitle,
  Row,
  RecordIcon
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

import Avatar from 'components/BusinessCard/components/Avatar'
import Name from 'components/BusinessCard/components/Name'

import styled from 'styled-components'
const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Person = () => {
  const {
    callbacks: {
      avatarUpdateCallback
    },
    resource: person
  } = useBusinessCard()

  return (
    <>
      <SectionTitle>
        <Row>
          {avatarUpdateCallback ? (
            <Avatar record={person} recordType="person" callback={avatarUpdateCallback}>
              <AvatarWrapper>
                {!/missing/.test(person.image_thumb_url)
                  ? <img src={person.image_thumb_url} alt={person.full_name} />
                  : <RecordIcon className="fas fa-user" />}

                <div style={{ maxWidth: '100%' }} data-cypress="business-card-person-name"><Name /></div>
              </AvatarWrapper>
            </Avatar>
          ) : (
            <AvatarWrapper>
              {!/missing/.test(person.image_thumb_url)
                ? <img src={person.image_thumb_url} alt={person.full_name} />
                : <RecordIcon className="fas fa-user" />}

              <div style={{ maxWidth: '100%' }} data-cypress="business-card-person-name"><Name /></div>
            </AvatarWrapper>
          )}
        </Row>
      </SectionTitle>
    </>
  )
}

export default Person
