import React from 'react'

import sanitizeAndLinkifyHtml from 'modules/sanitizeAndLinkifyHtml'

import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

import styled from 'styled-components'
export const DivClamp = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
`

const Description = () => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()

  const description = showOnlyFields.includes('description')
  const summary = showOnlyFields.includes('summary')

  if (!description && !summary) return null

  const displayValue = resource.description || resource.summary

  return (
    <Section>
      <Row>
        <Left>
          Summary
        </Left>
        <Right>
          <DivClamp dangerouslySetInnerHTML={{ __html: sanitizeAndLinkifyHtml(displayValue) }} />
        </Right>
      </Row>
    </Section>
  )
}

export default Description
