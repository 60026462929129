import React from 'react'

import {
  SectionTitle,
  Row,
  RecordIcon
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'
import Avatar from 'components/BusinessCard/components/Avatar'
import Name from 'components/BusinessCard/components/Name'

import styled from 'styled-components'
const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Company = () => {
  const { callbacks: { avatarUpdateCallback }, resource: company } = useBusinessCard()

  return (
    <>
      <SectionTitle>
        <Row>
          {avatarUpdateCallback ? (
            <Avatar record={company} recordType="company" callback={avatarUpdateCallback}>
              <AvatarWrapper>
                {!/missing/.test(company.image_thumb_url)
                  ? <img src={company.image_thumb_url} alt={company.name} />
                  : <RecordIcon className="fas fa-user" />}

                <div style={{ maxWidth: '100%' }} data-cypress="business-card-company-name"><Name /></div>
              </AvatarWrapper>
            </Avatar>
          ) : (
            <AvatarWrapper>
              {!/missing/.test(company.image_thumb_url)
                ? <img src={company.image_thumb_url} alt={company.name} />
                : <RecordIcon className="fas fa-user" />}

              <div style={{ maxWidth: '100%' }} data-cypress="business-card-company-name"><Name /></div>
            </AvatarWrapper>
          )}
        </Row>
      </SectionTitle>
    </>
  )
}

export default Company
